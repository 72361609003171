import React, { useState, useEffect } from "react";
import "./style.css";
import heroImg from "../../assets/images/hero-img (2).png";
import heroImgMobile from "../../assets/images/Gaining knowledge is the first step to wisdom, sharing it is the first step to humanity..png";
import secondImg from "../../assets/images/second.png";
import "aos/dist/aos.css";
import AOS from "aos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdAccessTime, MdOutlineNotes } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import fourth1 from "../../assets/images/fourth/1.png"
import fourth2 from "../../assets/images/fourth/2.png"
import logo from "../../assets/images/logo-white.png";
import fourth3 from "../../assets/images/fourth/3.png"
import fourth4 from "../../assets/images/fourth/4.png"
import fourthMain from "../../assets/images/fourth.webp"
import appstore from "../../assets/images/app-store-download-button.png";
import playstore from "../../assets/images/play-store-download-button.png";
import {
  FaArrowRight,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagramSquare,
} from "react-icons/fa";



function Home() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedType, setSelectedType] = useState("course");

  const settings = {
    slidesToShow: 3,
    speed: 500,
    dots: true,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1008,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const coursesData = [
    {
      id: 1,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/1.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-3.png',
      name: 'FLow assurance course using OLGA simulation.',
      instructor: 'Adbel aziz',
      experience: 'senior process engineer with 15+ years of experience, Eqypt.',
      type: 'course'
    },
    {
      id: 2,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/2.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-3.png',
      name: 'Process design engineering detailed course.',
      instructor: 'Adbel aziz',
      experience: 'senior process engineer with 15+ years of experience, Eqypt.',
      type: 'course'
    },
    {
      id: 3,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/3.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'ASPEN HYSYS simulator introductory course.',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'course'
    },
    {
      id: 4,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/4.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'Flare design using Aspen Flarenet',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'course'
    },
    {
      id: 5,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/5.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'Flare design using Aspen Flarenet',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'workshop'
    },
    {
      id: 6,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/9.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'Flare design using Aspen Flarenet',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'workshop'
    },
    {
      id: 7,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/6.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'Flare design using Aspen Flarenet',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'workshop'
    },
    {
      id: 8,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/7.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'Flare design using Aspen Flarenet',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'workshop'
    },
    {
      id: 9,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/8.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'Flare design using Aspen Flarenet',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'event'
    },
    {
      id: 10,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/9.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'Flare design using Aspen Flarenet',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'event'
    },
    {
      id: 11,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/1.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'Flare design using Aspen Flarenet',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'event'
    },
    {
      id: 12,
      imgSrc: 'https://alchemial.com/alchemialAssets/third/2.png',
      imgMiddleSrc: 'https://alchemial.com/alchemialAssets/third/E-1.png',
      name: 'Flare design using Aspen Flarenet',
      instructor: 'Another Instructor',
      experience: 'Instructor with expertise',
      type: 'event'
    },
  ];

  const filterCoursesByType = (type) => {
    return coursesData.filter((course) => course.type === type);
  };

  const filteredCourses = filterCoursesByType(selectedType);


  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <>
      <div className="hero">
        <div className="hero-inside">
          <h2 data-aos="fade">
            <span>Achieve excellence in your career,</span> <br />
            Securing your passport to the future.
          </h2>
          <a className="hero-btn" data-aos="fade">
            <p>Get Started</p> <FaArrowRight />
          </a>
          <div className="hero-img">
            <img
              src={windowWidth > 450 ? heroImg : heroImgMobile}
              alt="Hero Image"
              data-aos="fade-up"
            />
          </div>
        </div>
      </div>
      <div className="second">
        <div className="second-main" data-aos="fade-up-right">
          <div className="sm-left">
            <img src={secondImg} alt="second" />
          </div>
          <div className="sm-right" data-aos="fade-up-left">
            <h2>
              Join us to Connect, Learn and Grab{" "}
              <span>your passport to the future..</span>
            </h2>
            <p>
              We at Alchemial, connect you to the finest Industry experts from
              all around the world through our platform which will help you get
              the skills required for your desired job. We offer courses,
              workshops and webinars that are in demand as per Industry
              standards.
            </p>
            <a href="./" className="basic-btn">
              Explore Courses
            </a>
          </div>
        </div>
      </div>

      <div className="third">
        <div className="third-main">
          <h3>Connect to best Industry Experts</h3>
          <h2>Explore your upcoming Events, Courses and Workshops.</h2>

          <div className="third-main-inside">
          <div className="third-tabs">
              <div
                className={`tt-items ${selectedType === "course" ? "active" : ""}`}
                onClick={() => handleTypeChange("course")}
              >
                Courses
              </div>
              <div
                className={`tt-items ${selectedType === "workshop" ? "active" : ""}`}
                onClick={() => handleTypeChange("workshop")}
              >
                Workshops
              </div>
              <div
                className={`tt-items ${selectedType === "event" ? "active" : ""}`}
                onClick={() => handleTypeChange("event")}
              >
                Events
              </div>
              </div>
          </div>

          <Slider {...settings}>
          {filteredCourses.map((course) => (
        <div className="third-item" key={course.id}>
          <div className="third-top">
            <img src={course.imgSrc} alt={`Course ${course.id}`} />
          </div>
          <div className="third-middle">
            <img src={course.imgMiddleSrc} alt={`Instructor ${course.id}`} />
            <div className="tm-left">
              <h2>{course.instructor}</h2>
              <p>{course.experience}</p>
            </div>
          </div>
          <div className="third-bottom">
            <h2>{course.name}</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
            <div className="ti-info">
              <div className="tii-item">
                <MdAccessTime />
                <p>40 + Total hours</p>
              </div>
              <div className="tii-item">
                <MdOutlineNotes />
                <p>Study Material and Recordings</p>
              </div>
              <div className="tii-item">
                <BiSupport />
                <p>Software installation assists</p>
              </div>
            </div>
          </div>
        </div>
      ))}
            
            </Slider>
        </div>      
      </div>

      <div className="fourth">
            <div className="fourth-inside">
                <h2>What Alchemial offers you?</h2>
                <div className="fourth-main">
                    <div className="fm-left">
                        <div className="fml-item">
                          <img src={fourth1} alt="fourth-1" />
                          <h2>Live Interactive classes.</h2>
                          <p>Engaging live sessions, real-time interaction, dynamic learning, immediate feedback, collaborative environment, responsive teaching, interactive discussions, personalized instruction.</p>
                        </div>
                        <div className="fml-item">
                          <img src={fourth2} alt="fourth-1" />
                          <h2>Dedicated industry experts.</h2>
                          <p>Engaging live sessions, real-time interaction, dynamic learning, immediate feedback, collaborative environment, responsive teaching, interactive discussions, personalized instruction.</p>
                        </div>
                        <div className="fml-item">
                          <img src={fourth3} alt="fourth-1" />
                          <h2>Software installation assists.</h2>
                          <p>Engaging live sessions, real-time interaction, dynamic learning, immediate feedback, collaborative environment, responsive teaching, interactive discussions, personalized instruction.</p>
                        </div>
                        <div className="fml-item">
                          <img src={fourth4} alt="fourth-1" />
                          <h2>Study material and recordings.</h2>
                          <p>Engaging live sessions, real-time interaction, dynamic learning, immediate feedback, collaborative environment, responsive teaching, interactive discussions, personalized instruction.</p>
                        </div>
                    </div>
                    <div className="fm-right">
                        <img src={fourthMain} alt="fourth-main" />
                    </div>
                </div>
            </div>
      </div>

      {/* Community section */}

      <div className="community">
        <div className="content-community">
          <div className="community-left">
            <h1>
              <span className="ready">Ready?</span>
              <span>Let's Upskill with us.</span>
            </h1>
          </div>

          <div className="community-right">
            <div className="community-right-content">
              <div className="cm-rg-btn1">
                <p>Start for free</p>
              </div>
              <div className="cm-rg-btn2">
                <p>Product demo</p>
              </div>
            </div>
          </div>
        </div>
      </div>

{/* Footer */}
<footer>
        <div className="container">
          <div className="content-footer">
            <div className="profile">
              <div className="logo__area">
                <img src={logo} alt="" />
                <span className="logo__name">Bring people together</span>
              </div>
              <div className="desc__area">
                <a href="#"><img src={appstore} alt="" /></a>
                <a href="#"><img src={playstore} alt="" /></a>
              </div>
            </div>
            <div className="service__area">
              <ul className="service__header">
                <li className="service__name">ALCHEMIAL</li>
                <li>
                  <a href="#">Start for free</a>
                </li>
                <li>
                  <a href="#">Login</a>
                </li>
                <li>
                  <a href="#">Pricing</a>
                </li>
              </ul>
              <ul className="service__header">
                <li className="service__name">FEATURES</li>
                <li>
                  <a href="#">
                    WhatsApp <br /> Community
                  </a>
                </li>
                <li>
                  <a href="#">Even Hosting</a>
                </li>
                <li>
                  <a href="#">Paid Membership</a>
                </li>
                <li>
                  <a href="#">Newsletters</a>
                </li>
                <li>
                  <a href="#">Online Courses</a>
                </li>
                <li>
                  <a href="#">Chat Integration</a>
                </li>
                <li>
                  <a href="#">Analytics</a>
                </li>
              </ul>
              <ul className="service__header">
                <li className="service__name">USECASES</li>
                <li>
                  <a href="#">For Developers</a>
                </li>
                <li>
                  <a href="#">For Educators</a>
                </li>
                <li>
                  <a href="#">For Finance</a>
                </li>
                <li>
                  <a href="#">For Networking</a>
                </li>
                <li>
                  <a href="#">For Content Creator</a>
                </li>
              </ul>
              <ul className="service__header">
                <li className="service__name">COMPANY</li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Career</a>
                </li>
                <li>
                  <a href="#">Academy</a>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="footer__bottom">
            <div className="tou">
              <div className="social_media">
                <a href="#">
                  <FaFacebook />
                </a>
                <a href="#">
                  <FaTwitter />
                </a>
                <a href="#">
                  <FaLinkedin />
                </a>
                <a href="#">
                  <FaInstagramSquare />
                </a>
              </div>
              <div className="terms">
                <a href="#">Terms of Services</a>
                <a href="#">Privacy</a>
              </div>
            </div>
            <div className="copy_right">
              <p>Copyright @ 2023</p>
            </div>
          </div>
        </div>
      </footer>

    </>
  );
}

export default Home;
