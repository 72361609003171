import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home/Home';
import About from './pages/about/About';
import Register from './pages/register/Register';
import Navbar from './components/navbar/Navbar';


function App() {
  return (
    <BrowserRouter>
    <Navbar />
    <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Home />} />
        <Route path="/register" element={<Register />} />

    </Routes>
  </BrowserRouter>
  );
}

export default App;
