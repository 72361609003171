import React, { useEffect, useState } from "react";
import "./style.css";
import logo from "../../assets/images/logo-white.png";
import logoSmall from "../../assets/images/logo-only.png";

function Navbar() {
  const [isActive, setIsActive] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 300) {
        if (scrollTop > lastScrollTop) {
          setIsActive(false);
        } else {
          setIsActive(true);
        }
      } else {
        setIsActive(true);
      }

      lastScrollTop = scrollTop;
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850);
    };

    handleResize();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`navbar ${isActive ? "active" : ""}`}>
      <div className="navbar-left">
      <img src={isMobile ? logoSmall : logo} alt="logo" />
      </div>
      <div className="navbar-middle">
        <ul className="nav-links">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/">About</a>
          </li>
          <li>
            <a href="/">Courses</a>
          </li>
          <li>
            <a href="/">Contact</a>
          </li>
        </ul>
      </div>
      <div className="navbar-right">
        <div className="login">
          <a href="/" className="navbar-btn">
            {" "}
            Login{" "}
          </a>
          <a href="/" className="navbar-btn">
            {" "}
            Register{" "}
          </a>
        </div>
      </div>

      <div
        className={`burger ${isActive2 ? "active" : ""}`}
        onClick={() => {
          setIsActive2((prev) => !prev);
        }}
      >
        {" "}
        <div className="line-1 b-line"></div>
        <div className="line-2 b-line"></div>
        <div className="line-3 b-line"></div>
      </div>

      <div className={`res-nav-links ${isActive2 ? "active" : ""}`}>
        <div className="res-nav-links-in">
          <div className="res-navbar-middle">
            <ul className="res-nav-links-ul">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">Packages</a>
              </li>
              <li>
                <a href="/">About</a>
              </li>
              <li>
                <a href="/">Contact</a>
              </li>
            </ul>
          </div>
          <div className="res-navbar-right">
            <div className="res-login">
              <a href="/" className="res-navbar-btn">
                {" "}
                Login{" "}
              </a>
              <a href="/" className="res-navbar-btn">
                {" "}
                Register{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
